<template>
  <div>
    <el-form
      class="form-item-no-mb"
      ref="searchForm"
      inline
      :model="searchForm"
      size="small"
    >
      <el-form-item label="课程名称">
        <el-input
          v-model="searchForm.courseName"
          clearable
          placeholder="请输入课程名称"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="reset">重置</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary">查询</el-button>
      </el-form-item>
    </el-form>

    <div class="content-center">
      <div class="stage-box-list">
        <div class="empty-box" v-if="stageList.length === 0">
          <PlaceholderMap text="暂无课程目录噢~" />
        </div>
        <div class="stage-box-list-info" v-else>
          <div class="stage-box-left">
            <div class="stage-box-list-info-l">
              <div class="stage-box-list-info-l-n">
                线上课程名称 <span>入职培训</span>
              </div>
              <div class="stage-box-list-info-l-t">
                <span>学习时长： 10分钟</span>
                <span class="line-box-two"></span>
                <span>开始时间： 2023-10-20</span>
                <span class="line-box-two"></span>
                <span>所属学习计划： 入职培训计划</span>
              </div>
            </div>
          </div>
          <div class="stage-box-list-info-r">
            <el-button class="add-group-btn" @click="seeDetail"
              >查看详情</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      searchForm: {
        courseName: '' // 课程名称
      },
      stageList: [11]
    }
  },
  methods: {
    reset () {
      this.searchForm = {}
    },
    seeDetail () {
      this.$router.push({
        path: '/myOnlineCourseDetails'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.content-center {
  background: #ffffff;
  height: 671px;
  overflow: auto;
  padding: 24px;
  margin-top: 20px;
}
.stage-box-list {
  .empty-box {
    margin-top: 16px;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .stage-box-list-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
    margin-bottom: 16px;
    background: linear-gradient(to bottom, #f0f8ff, #ffffff);
    border: 1px solid #e7f4ff;
    border-radius: 8px;
    .stage-box-list-info-l {
      .stage-box-list-info-l-n {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        span {
          display: inline-block;
          margin-left: 16px;
          padding: 2px 12px;
          background-color: #0089ff;
          font-size: 12px;
          font-weight: 400;
          color: #ffffff;
          border-radius: 8px 8px 8px 0;
        }
      }
      .stage-box-list-info-l-t {
        display: flex;
        align-items: cenetr;
        font-size: 12px;
        color: #666666;
        line-height: 16px;
        .line-box-two {
          display: inline-block;
          margin: 0 12px;
          width: 1px;
          height: 16px;
          background-color: #dcdcdc;
        }
      }
    }
  }
}
.stage-box-left {
  display: flex;
  align-items: center;
}
/deep/ .form-item-no-mb {
  background: #ffffff;
  padding: 16px 24px;
}
/deep/ .el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  margin-bottom: 0;
}
.add-group-btn {
  width: 88px;
  border: 1px solid #0089ff;
  font-size: 14px;
  color: #0089ff;
}
</style>
